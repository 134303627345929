import React from 'react';
import { Box, Grid, Typography,Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';

const ImageSlider = ({ slides }) => {
  return (
    <Box sx={{ mb: 5 }}>
      <Carousel autoPlay animation="slide" indicators={false} navButtonsAlwaysVisible>
        {slides.map((slide, index) => (
          <Grid container spacing={2} key={index} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center" sx={{ height: '500px' }}>
                <img 
                  src={slide.image} 
                  alt={`slide-${index}`} 
                  style={{ 
                    width: '100%', 
                    maxWidth: '400px',
                    borderRadius: '10px', 
                    objectFit: 'cover',
                  }} 
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>
                  {slide.title}
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                  {slide.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Carousel>
      <Box sx={{ textAlign: 'center', mt: 5, mb: 5 }}>
      <Button 
        component={Link} 
        to="https://api.whatsapp.com/send?phone=51945429543&text=%C2%A1Hola%20Roberto!%20Quiero%20ver%20una%20demo%20de%20la%20app%20para%20DJs%20%F0%9F%8E%B6%F0%9F%94%A5" 
        variant="outlined" 
        color="secondary" 
        size="large"
        sx={{ color:'#ffffff', backgroundColor:'#115beb' }}
      >
        ¡Pruebalo ahora!
      </Button>
    </Box>
    </Box>
  );
};

export default ImageSlider;
