import React from 'react';
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Button, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoExtendido from '../assets/Logo DJ Ponla extendido (sin fondo).png';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <img src={LogoExtendido} alt="Logo DJPonla" style={{ height: '70px' }} />
        </Box>
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" onClick={handleMenu}>
              <MenuIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem component="a" href="#testimonials" onClick={handleClose}>Testimonios</MenuItem>
              <MenuItem component="a" href="#djs" onClick={handleClose}>Nuestros DJs</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button color="inherit" component="a" href="#testimonials">Testimonios</Button>
            <Button color="inherit" component="a" href="#djs">Nuestros DJs</Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
